<template>
  <div class="h-0"></div>
</template>

<script>
import Recorder from "js-audio-recorder";

import { meetingInterview } from "@/networking/urlmanager";

export default {
  name: "sound-question",
  props: ["questionState", "questionType", "expectedAnswer"],
  data() {
    return {
      recorder: new Recorder({
        sampleBits: 16,
        sampleRate: 44100,
        numChannels: 1,
      }),
      loader: false,
      isRecording: false,
      mediaStream: null,
    };
  },
  methods: {
    startRecording() {
      if (this.isRecording) return;
      this.recorder.start().then((stream) => {
        this.mediaStream = stream;
        this.isRecording = true;
      });
    },
    async stopRecording() {
      if (!this.isRecording) return;
      this.recorder.stop();

      // Stop the media tracks (microphone)
      if (this.mediaStream) {
        const tracks = this.mediaStream.getTracks();
        tracks.forEach((track) => track.stop());
      }

      // Process the recording
      const audioBlob = this.recorder.getWAVBlob();
      this.isRecording = false;
      this.$emit("load", true);
      await this.processRecording(audioBlob);
    },

    async getFormData() {
      if (this.questionState && this.questionType && this.expectedAnswer) {
        return {
          questionState: this.questionState,
          questionType: this.questionType,
          expectedAnswer: this.expectedAnswer,
        };
      } else {
        const { questionState, questionType, expectedAnswer, question } = this.$store.state.questionsAndAnswer[this.$store.state.answerPage];
        return { questionState, questionType, expectedAnswer, question };
      }
    },
    async processRecording(blob) {
      let result = await this.getFormData();

      if (this.$store.state.candidate.isQuestion == 1 && !result?.question) {
        result.question = this.$store.state.isCandidateQuestion.text;
        result.expectedAnswer = 1;
        result.isAsistanPage = true;
      }

      const formData = new FormData();
      formData.append("file", blob, "audio.wav");
      formData.append("questionState", result?.questionState);
      formData.append("questionType", result?.questionType);
      formData.append("expectedAnswer", result?.expectedAnswer);
      formData.append("question", result?.question);

      this.loader = true;
      try {
        const res = await this.axios.post(
          meetingInterview.questionConverter,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const item = res.data.data;
        if (item.formatAnswer) {
          this.$store.commit("setPreInterviewAnswer", item.formatAnswer);
        }

        if (item.isType) {
          this.$emit("isError", false);
          this.$emit("changeAnswer", item.text);
          this.$emit("expectedAnswerState", {
            questionState: item.questionState,
            expectedAnswerState: item.expectedAnswerState,
          });
          this.$emit("load", false);
        } else {
          this.$emit("isError", true);
          this.$emit("resetButtonStep", true);
          this.$emit("load", false);
        }
      } catch (err) {   
        this.$emit("isError", true);
        this.$emit("resetButtonStep", true);
        this.$emit("load", false);

        this.$emit("notVoice", true)

        this.loader = false;
      }
    },
  },
};
</script>
