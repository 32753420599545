<template>
  <div class="relative group flex justify-center items-center" :class="containerClass">
    <button :disabled="isDisabled" :type="buttonType" @click="handleClick" :class="buttonClasses">
      <img v-if="shouldShowImage" :src="imgSrc" :class="[imgClass || 'w-4 mr-3']" :alt="alt" />
      <span v-if="!load" class="text-center"> {{ buttonText }}</span>
      <span v-else class="flex gap-1.5 justify-center items-center">
        <div v-if="shouldShowImage" :class="spinnerClasses"></div>
        <p>{{ loadText || $t("components.button.loading") }}</p>
      </span>
    </button>

    <tooltip v-if="isTooltip" class="mt-2" :title="toolTipTitle" :tipClass="toolTipClass" :top="tooltipTop"
      :bottom="tooltipBottom" :rotateClass="tooltipRotateClass" :rotatetopClass="tooltipRotatetopClass" />
  </div>
</template>

<script>
import tooltip from "@/components/tooltip.vue";

export default {
  name: "AsyncButton",
  props: [
    "type",
    "imgHidden",
    "imgSrc",
    "text",
    "loadText",
    "load",
    "buttonClass",
    "containerClass",
    "disabled",
    "imgClass",
    "spinnerClass",
    "alt",
    "toolTipTitle",
    "toolTipClass",
    "tooltipTop",
    "tooltipBottom",
    "tooltipRotateClass",
    "tooltipRotatetopClass",
    "isTooltip",
  ],
  components: {
    tooltip,
  },
  computed: {
    isDisabled() {
      return this.load || this.disabled;
    },
    buttonType() {
      return this.type || "submit";
    },
    shouldShowImage() {
      return !this.imgHidden && this.imgSrc && !this.load;
    },
    buttonText() {
      return this.load
        ? this.loadText || this.$t("components.componentsbutton.loading")
        : this.text || this.$t("components.button.send");
    },
    spinnerClasses() {
      return [
        "w-4 h-4 rounded-full animate-spin border-2 border-solid border-white border-t-transparent shadow-md",
        this.spinnerClass,
      ];
    },
    buttonClasses() {
      return [
        "px-4 h-11 w-full py-2 text-[15px] rounded max-md:text-sm ",
        this.buttonClass ? this.buttonClass : "bgcol text-white",
        " ",
        { "flex items-center gap-2": this.shouldShowImage },
      ];
    },
  },
  methods: {
    handleClick() {
      this.$emit("click", true);
    },
  },
};
</script>
