<template>
  <div v-if="load" class="w-full h-full flex justify-center items-center">
    <div class="w-full">
      <div class="w-64 h-64 max-lg:w-36 max-lg:h-36 flex items-center pb-6 mx-auto">
        <img src="@/assets/video/asistans.png" alt="" class="w-64 h-64 rounded-full max-lg:w-36 max-lg:h-36" />
      </div>

      <div class="mt-8 w-11/12 md:w-6/12 mx-auto">
        <p class="font-bold text-white text-2xl text-center">{{ $t("preInterview.firstStep.loading.title") }}</p>
        <p class="block w-full font-light text-gray-100 text-center">
          {{ $t("preInterview.firstStep.loading.description") }}
        </p>
      </div>
      <div class="mt-10">
        <loadingCustom />
      </div>
    </div>
  </div>
</template>
<script>
import loadingCustom from "@/components/loading.vue";
export default {
  name: "loading-costum",
  components: {
    loadingCustom,
  },
  props: ["load"],
};
</script>
<style>
.boxContainer {
  display: flex;
  justify-content: space-between;
  --boxSize: 4px;
  --gutter: 10px;
  width: calc((var(--boxSize) + var(--gutter)) * 5.5);
}

.box {
  transform: scaleY(0.4);
  height: 100%;
  width: var(--boxSize);
  background: white;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  border-radius: 6px;
}

.box1 {
  transform: scaleY(0.3);
}

.box2 {
  transform: scaleY(0.6);
}

.box3 {
  transform: scaleY(1);
}

.box4 {
  transform: scaleY(0.6);
}

.box5 {
  transform: scaleY(0.3);
}

.containerBar {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: widhBar;
  animation-duration: 2s;
}

@keyframes widhBar {
  25% {
    @apply w-3/12;
  }

  50% {
    @apply w-4/12;
  }

  75% {
    @apply w-3/12;
  }
}
</style>
