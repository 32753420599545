<template>
  <div class="audio-player">
    <!-- You can add player controls or loading indicator here if needed -->
  </div>
</template>

<script>
import { Howl, Howler } from 'howler'
import { meetingInterview } from '@/networking/urlmanager'

export default {
  name: 'AudioPlayer',

  props: {
    value: {
      type: [String, Object],
      required: true,
      validator(value) {
        if (typeof value === 'string') return true
        if (typeof value === 'object') {
          return value.url || value.text || value.buffer
        }
        return false
      }
    }
  },

  data() {
    return {
      sound: null,
      isLoading: false,
      isPlaying: false,
      isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
      isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    }
  },

  methods: {
    async createAudioFromBuffer(buffer) {
      const uint8Array = new Uint8Array(buffer.data)
      const blob = new Blob([uint8Array], { type: 'audio/mp3' })
      return URL.createObjectURL(blob)
    },

    async getAudioUrl() {
      try {
        if (typeof this.value === 'string') {
          const response = await this.axios.post(meetingInterview.textToSpeech, {
            text: this.value
          })
          return this.createAudioFromBuffer(response.data.data.buffer)
        }

        const { url, buffer, text } = this.value;
        
        if (url) return url
        else if (buffer) return this.createAudioFromBuffer(buffer)
        else if (text) {
          const response = await this.axios.post(meetingInterview.textToSpeech, {
            text
          })
                    
          return this.createAudioFromBuffer(response.data.data.buffer)
        }
      } catch (error) {
        console.error('Error in getAudioUrl:', error)
        throw error
      }
    },

    stopCurrentSound() {
      if (this.sound) {
        this.sound.stop()
        this.sound.unload()
        this.sound = null
        this.isPlaying = false
      }
    },

    initializeHowl(audioUrl) {
      const config = {
        src: [audioUrl],
        format: ['mp3'],
        html5: true,  // Force HTML5 Audio for better mobile handling
        volume: 0.5,
        autoplay: true,
        onplayerror: () => {
          this.sound.once('unlock', () => {
            this.sound.unload()
            this.sound.load()
            this.sound.play()
          })
        },
        onplay: () => {
          this.isPlaying = true
          this.$emit('startValue', true)
          
          // iOS için ses kilidini kaldır
          if (this.isIOS && Howler.ctx && Howler.ctx.state === 'suspended') {
            Howler.ctx.resume()
          }
        },
        onend: () => {
          this.isPlaying = false
          this.$emit('endValue', true)
        },
        onloaderror: (id, error) => {
          console.error('Audio loading error:', error)
          this.$emit('error', error)
          this.isPlaying = false
        },
        onstop: () => {
          this.isPlaying = false
        }
      }

      // Safari/iOS için ek ayarlar
      if (this.isSafari || this.isIOS) {
        config.preload = true
        config.pool = 1
        config.orientation = 'portrait' // iOS ses yönlendirmesi için
        config.mobileAudioFocus = true // iOS ses odağı için
      }

      return new Howl(config)
    },

    async synthesizeSpeech() {
      if (!this.value) return

      try {
        this.isLoading = true
        const audioUrl = await this.getAudioUrl()

        // Önceki sesi durdur
        if (this.sound) {
          this.stopCurrentSound()
        }

        // Yeni ses oluştur ve başlat
        this.sound = this.initializeHowl(audioUrl)

        // iOS/Safari için ses kilidini kontrol et
        if ((this.isSafari || this.isIOS) && Howler.ctx && Howler.ctx.state === 'suspended') {
          await Howler.ctx.resume()
        }
      } catch (error) {
        console.error('Failed to create audio output:', error)
        this.$emit('error', error)
        this.isPlaying = false
      } finally {
        this.isLoading = false
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.synthesizeSpeech()
        }
      }
    }
  },

  beforeDestroy() {
    this.stopCurrentSound()
  }
}
</script>