<template>
  <div id="app" class="bg-[#10162d]/10 min-h-screen transition-all ease-in-out ">
    <checkInternet
      successText="İnternet Bağlantınız Tekrar Sağlandı"
      errorText="İnternet bağlanıtınız Koptu"
    />

    <navbar v-if="$route.name != 'notFound'" />
    <router-view class="min-h-screen-24 px-24 max-md:px-0 flex items-center justify-center  " />
    <img class="w-full fixed bottom-0 inset-x-0 -z-10 opacity-20" src="./assets//vectors/grid.svg" alt="grid">
  </div>
</template>
<script>
import navbar from "@/partitions/navbar.vue";
import checkInternet from "check-to-network";
import moment from 'moment'
export default {
  components: {
    navbar,
    checkInternet,
  },
  created() {
    this.$i18n.locale = this.$store.state.language.code;
    moment.locale(this.$store.state.language.code)

    this.$store.commit("setDateSelected", false);
    this.$store.commit("setInterviewDone", false);
  },
};
</script>
