<template>
  <div class="bg-[#10162d] border-b border-gray-700 h-14 py-3 px-28 max-sm:px-3 flex justify-between items-center">
    <button class="h-full">
      <img
        v-if="isAssessmentRoute"
        src="@/assets/cvusLogoB.png"
        alt=""
        class="h-full"
      />
      <img v-else src="@/assets/cvusLogo.png" alt="" class="h-full" />
    </button>
    <div
      class="text-white uppercase flex items-center space-x-4"
      :class="this.isTimerActive ? '' : 'hidden'"
    >
      <p>Kalan Süre (Dakika):</p>
      <p>{{ remainingTime }}</p>
    </div>
    <SettingsMenu
      v-if="
        this.$route.name === 'interviewInformation' ||
        this.$route.name === 'questionAndAnswer' ||
        this.$route.name === 'candidateQuestion'
      "
    />
  </div>
</template>

<script>
import SettingsMenu from "@/components/settingsMenu.vue";

export default {
  name: "navbar-meeting",
  components: {
    SettingsMenu,
  },
  data() {
    return {};
  },
  computed: {
    remainingTime() {
      return this.$store.state.remainingTime;
    },
    isAssessmentRoute() {
      return this.$route.path.startsWith("/assessment");
    },
    isTimerActive() {
      return this.$store.state.isTimerActive;
    },
  },
  mounted() {},
  beforeDestroy() {
    this.$store.dispatch("stopTimer");
  },
  watch: {
    remainingTime() {
      if (this.remainingTime === 0) {
        this.$store.dispatch("stopTimer");
        this.$router.push("/assessment/end");
      }
    },
  },
};
</script>
