import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/css/style.css";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

Vue.use(VueSweetalert2);
Vue.config.productionTip = false;

//modal kütüphanesi
import VModal from "vue-js-modal/dist/ssr.nocss";
import "vue-js-modal/dist/styles.css";


Vue.use(VModal, {
  componentName: "modal",
  dynamicDefault: { draggable: true, resizable: false },
});


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});


//axios İnstance
import axiosInstance from "./plugins/axiosInstance";
Vue.prototype.axios = axiosInstance;

import commonMixins from "./plugins/commonMixins";
Vue.mixin(commonMixins);

import i18n from "./plugins/i18n";

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
