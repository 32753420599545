<template>
  <div class="w-full flex flex-col space-y-6">
    <soundQuestion ref="soundQuestion" @changeAnswer="val => speakText = val" @load="val => load = val"
      @resetButtonStep="resetButton" @startValue="startValue" @endValue="endValue" @isError="val => isError(val)"
      @expectedAnswerState="val => changeExpectedAnswerState(val)" @notVoice="val => notVoice(val)" />

    <div class="w-full rounded-full flex items-center justify-center">
      <asistan :gifActive="gifActive" />
    </div>

    <speechAnimation :botSepeak="botSepeak" :speechReady="speechReady" />

    <textToItem :value="isFakeLoad ? '' : getText" ref="textItem" @startValue="startValue" @endValue="endValue"
      @setLoading="val => speechReady = val" />

    <div class="w-full flex justify-center items-center">
      <p class="mx-auto text-gray-400 text-[15px] px-4 max-md:text-xs text-center">
        {{ formatText(currentQuestion) }}
      </p>
    </div>
  </div>
</template>

<script>
import speechAnimation from "../../components/speechAnimation.vue"
import textToItem from "../../components/textToItem.vue"
import soundQuestion from "../../components/soundQuestion.vue"
import asistan from "@/components/asistans.vue"

export default {
  name: "content-panel",
  props: ["loadingState"],
  components: {
    speechAnimation,
    textToItem,
    soundQuestion,
    asistan
  },
  data() {
    return {
      gifActive: false,
      speakText: "",
      isCandidate: false,
      erorState: false,
      botSepeak: false,
      load: false,
      speechReady: false,
      isMobileDevice: window.innerWidth <= 768,
      isSoundPlaying: false,
      isFakeLoad: true,
      voiceNotUnderstood: false
    }
  },
  methods: {
    resetButton() {
      this.$emit("resetStep", true)
    },
    startValue() {
      this.gifActive = true
      this.$emit("isSounEnded", false)
      this.botSepeak = true
      this.$emit("isDisabled", true)
      this.isSoundPlaying = true
    },
    notVoice(val) {
      this.voiceNotUnderstood = true
      this.erorState = true
      if (val) {
        this.notificationAlert(this.$t("preInterview.fourStepAssistan.index.answerIsNotUnderstood"), "error");
        this.synthesizeSpeech()
      }
    },
    endValue() {
      this.gifActive = false
      this.$emit("isSounEnded", true)
      this.botSepeak = false
      this.$emit("isDisabled", false)
      if (!this.erorState) {
        this.voiceNotUnderstood = false
      }
      this.$emit("resetStep", false)
    },
    isError(val) {
      this.erorState = val
      if (val) {
        this.synthesizeSpeech()
      }
    },
    startRecording() {
      this.$refs.soundQuestion.startRecording()
    },
    stopRecording() {
      this.$refs.soundQuestion.stopRecording()
    },
    changeExpectedAnswerState(val) {
      if (val.expectedAnswerState == 2 && val.questionState == 2) {
        this.$store.commit("setIsDecision")
      }
      this.$emit("changeAnswer", this.speakText)
      this.$emit("changeExpectedAnswer", val.expectedAnswerState)
      this.$emit("nextStep")
      this.isSoundPlaying = false
    },
    synthesizeSpeech() {
      setTimeout(() => {
        this.$refs.textItem.synthesizeSpeech()
      }, 50)
    },
    formatText(text) {
      return text // Add any text formatting logic here
    }
  },
  computed: {
    currentQuestion() {
      return this.$store.state.questionsAndAnswer[this.$store.state.answerPage].question
    },
    getText() {
      const { speechUrl, question, type } = this.$store.state.questionsAndAnswer[this.$store.state.answerPage]
      if (!this.erorState) {
        return speechUrl ? { url: Array.isArray(speechUrl) ? speechUrl[0] : speechUrl } : question
      }
      if (type == 2 || type == 3) {
        return { url: this.$store.state?.constantSounds?.numericValueWarning?.url }
      } else if (type == 4) {
        return { url: this.$store.state?.constantSounds?.vagueAnswer?.url }
      }else if(this.voiceNotUnderstood){
        return { text: this.$t("preInterview.thirdStepInterview.content.notVoice") }
      }
      return { url: this.$store.state?.constantSounds?.outOfFormat?.url }
    }
  },
  created() {
    setTimeout(() => {
      this.isFakeLoad = false
      this.synthesizeSpeech()
    }, 200)
  },
  watch: {
    speakText(val) {
      this.$emit("changeAnswer", val)
    },
    load(val) {
      this.$emit("isLoad", val)
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>